.catimg {
  height: 320px;
  width: 200px;
  object-Fit: cover;
  object-Position: center center;
  width: 100%,
}

/* .catname {
  color: white;
  font-size: 35px;
  font-weight: 600;
  text-shadow: 4px 4px 10px #c70044, 3px 3px 10px #002e6a;
  margin-left: 19px;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-left: 35px;
  animation: pop-in 1s ease-in-out both;
} */

.category-link {
  text-decoration: none; /* Remove underline from the Link */
  color: inherit; /* Inherit the text color */
}
.catname {
  color: #002e6a;
  font-size: 22px;
  font-weight: 400;
  margin-top: 1px; /* Adjust margin to position the text below the image */
  display: block; /* Ensure the element takes up the full width */
  text-align: center; /* Center the text */
  animation: pop-in 1s ease-in-out both;
  text-decoration: none;
}
.catname:hover {
  text-shadow: 1px 1px 1px #c70044;
}



.review {
  display: flex;
  justify-content: space-between;
}

/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Pagination button styles */
.pagination button {
  background-color: #002E6A;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

/* Active page button style */
.pagination button.active {
  background-color: #c70044;
  color: white;
  font-weight: bold;
}


@keyframes pop-in {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  .g-4,
  .gy-4 {
    --bs-gutter-y: -0.5rem;
  }

  .review {
    display: flex;
    justify-content: space-between;
  }

}

@media screen and (max-width: 380px) {
  .g-4,
  .gy-4 {
    --bs-gutter-y: 1rem;
  }

  .review {
    display: flex;
    justify-content: space-between;
  }

  .android {
    width: 345px;
  }

}