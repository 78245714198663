.nav-link.active {
    /* border-bottom: 2px solid #C70044; */
    color: #C70044 ; /* add a bottom border */
}


@media screen and (max-width: 480px) {
    .mobile {
        /* margin-left: 70px; */
        display: flex;
        align-items: flex-start;
        margin-left: 15px;
    }
    .nav-item{
        font-weight: 250;
    }
    .hrik { 
        display: block;
        margin-block-end: auto;
        margin-block-start:  none;
        
        border: 1.2px solid;
        overflow: hidden;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: -12px;
        width: 180px;
    }
   
    
}
