.image-carousel {
  position: relative;
  width: 95%;
  height: 60vh; /* Change height to 30% of viewport height */
  margin-left: auto;
  margin-right: auto;
}

.carousel-item {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.carousel-item.active {
  opacity: 1;
}

.image-carousel img {
  height: 100%; /* Set image height to 100% of parent container */
  object-fit: contain; /* Scale image to fill the container */

  object-position: center center;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  font-size: 80px;
  cursor: pointer;
  color: #c70044;
  transition: opacity 0.8s ease-in-out;
  
}

.carousel-button:hover {
  opacity: 1;
  color: #002e6a;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

@media screen and (max-width: 480px) {
  .image-carousel img {
    object-fit: contain;
    object-position: center;
  }
  .image-carousel {
    height: 30vh;
    width: 98%;
  }
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    font-size: 20px;
    cursor: none;
    color: #c70044;
    transition: opacity 1s ease-in-out;
  }
}
