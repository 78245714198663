.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 100px;
}

.navbar-nav {
  font-weight: bolder;
  font-size: 18px;
  
}
.nav-link {
  color: #002e6a;
}
.nav-link:hover {
  color: #c70044;
}

.navbar-nav .nav-item.active .nav-link {
  color: #c70044 !important; /* Ensure active links are colored correctly */
  font-weight: bold; /* Optional for emphasis */
}

/* .navbar-nav .nav-item.active .nav-link:hover {
  color: #a50038; 
} */


.btn {
  color: #002e6a;
}

.button {
  color: white;
}

.btn:hover {
  color: #c70044;
}

.dropdown-item {
  font-weight: bolder;
  color: #002e6a;
}
.dropdown-item:hover {
  color: #c70044;
  background-color: white;
}
.navbar-collapse.show {
  background-color: whitesmoke !important;
  transition:ease;
}
#navbarNav {
  background-color: white !important;
  transition: ease;
  width: 100vw;
  margin-left: -12px;
  margin-right: -12px;
}

.mobile {
  margin-left: 20px;
}



@media screen and (max-width: 480px) {
  .logo {
    width: 150px;
    height: 60px;
    margin-right: 0px;
  }
  .mobileSearch{
    
    margin-bottom: -1px;
    width: 100%;
    margin-right: 0px;
    margin-top: 20px;
    
    
  }
  .nav-link {
    font-weight: 400;
  }
  .mobile {
    /* margin-left: 70px; */
    display: flex;
    align-items: flex-start;
    margin-left: 15px;
  
    
  }
  #navbarNav {
    background-color: rgb(255, 255, 255) !important;
    transition:ease-in-out;
    border-radius:5px;
    /* border-color: gainsboro; */
    border-color: gainsboro;
    border-style:solid;
    margin-left: 190px;
    margin-top: 7px;
    margin-right: 0.25px;
   

   
  }
  /* .hri { 
    display: block;
    margin-block-end: auto;
    margin-block-start:  none;
    border: 1.1px inset;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 42vw;
} */

.hri { 
  display: block;
  margin: 0 auto; /* Center horizontally if needed */
  border: 0.5px inset; /* Adjusted border thickness for better alignment */
  overflow: hidden;
  width: 48vw; /* Maintains the original width specification */
  margin-left: -15px;
  
}
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 78px;
}
  
.nav-item {
  font-weight: 150;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}




}



@media screen and (max-width: 350px) {
  .logo {
    width: 110px;
    height: 40px;
    margin-right: 0px;
    margin-right: -15px;
  }
  .mobileSearch{
    
    margin-bottom: 10px;
    width: 95%;
    margin-right: 1px;
    
  }
  .nav-item {
    font-weight: 450;
  }
  .mobile {
    /* margin-left: 70px; */
    display: flex;
    align-items: flex-start;
    margin-left: 15px;
  
    
  }
  #navbarNav {
    background-color: rgb(255, 255, 255) !important;
    transition:none;
    border-radius: 7px;
    border-color: gainsboro;
    border-style: solid;
    margin-left: 190px;
    margin-top: 9px;
   

   
  }
  .hri { 
    display: block;
    margin-block-end: auto;
    margin-block-start:  none;
    border: 1px inset;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 42vw;
}
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 78px;
  }
  


}

