h1 {
  color: #002e6a;
  font-weight: 700;
}

.reviews {
  background-color: #c70044;
  border-radius: 70px;
  height: 80px;
}
.reviews > p {
  color: white;
  font-size: 30px;
}
.disimg {
  height: 400px;
  width: 250px;
  box-shadow: 5px 5px #c70044;
}

.off {
  position: absolute;
  top: 0;
  right: 0;
  background: #f44336;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 0 0 0 8px;

}

@media (max-width: 576px) {
  .reviews p {
    font-size: 14px;
    line-height: 1.5;
  }
  .off {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 0 0 0 8px;
  }
  .product-list {
    display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right:-12px
    
  }

  .card {
    /* width:185px; */
    width: calc(100%); 
    margin-left: -5px;
    
  }
  
}
@media (max-width: 370px) {
  .reviews p {
    font-size: 14px;
    line-height: 1.5;
  }
  .off {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 0 0 0 8px;
  }
  .product-list {
    margin-right: -12px;
  }

  .card {
    width:170px;
    margin-left: -5px;
  }
}
