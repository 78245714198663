.dis-img {
  width: 320px;
  height: 430px;
  margin-bottom: 18px;
}
.size-button {
  border: 2px solid black;
  margin: 10px;
  border-radius: 10%;

  padding: 10px;
}
.size-button-active {
  border: 2px solid #c70044;
  margin: 10px;
  border-radius: 100%;
  background-color: #f8c5d7;
  padding: 10px;
}
.size-button:hover {
  border: 2px solid #c70044;
  background-color: #f8c5d7;
  border-radius: 100%;
  color: black;
}
.productPrice {
  font-size: 28px;
  font-weight: 500;
  color: #c70461;
}
.allName {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.pName {
  font-weight: 380;
  font-size: 29px;
}

.off {
  position: absolute;
  top: 0;
  right: 0;
  background: #f44336;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 0 0 0 8px;
}

@media screen and (max-width: 480px) {
  .allImages > .col-md-6 {
    flex-basis: 49%;

    max-width: 50%;
    justify-content: space-between;
  }
  .dis-img {
    height: 292px;
    width: 195px;
  }
  .rowKa {
    --bs-gutter-x: -0.25rem;
    --bs-gutter-y: -9.6rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.5px;
    row-gap: 1px;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
    margin-bottom: -90px;
  }
  .off {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 0 0 0 8px;
  }
}
