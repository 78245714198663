/* Default styles for desktop */
.cart-image {
  height: 190px;
  width: 150px;
  margin: 10px;
  object-fit: cover;
  box-shadow: 2px 2px 8px #c70044;
}
.cart-image-container {
  height: 200px;
  width: 200px;
}


.product-desc-container {
  height: 200px;
  width: 300px;
}

.cart-item > div {
  margin: 30px;
}

.clear-cart {
  color: red;
}

.clear-cart:hover {
  color: black;
}
.bb {
  display: flex;
  justify-content: flex-end;
}

/* Media query for mobile screens */
@media only screen and (max-width: 768px) {
  .cart-image {
    height: 170px;
    width: 150px;
    margin: 5px;
  }

  .cart-item > div {
    margin: 14px;
  }
  .bb {
    display: flex;
    justify-content: flex-end;
  }
  .bs{
    padding-left: 85px;
    padding-right: 85px;
    
  }
  
}
