.button
{
    color: white;
    background-color: #002E6A;
}

.button:hover
{
    color:#C70044;
    background-color: #002E6A;
}

.product-form-container {
    display: flex;
    justify-content: center;
}

.form-group {
    margin-bottom: 1.5rem;
}

.size-input,
.quantity-input {
    display: inline-block;
    margin-right: 10px;
}

.table-dark th{
    color: white;
}

.pagination-text {
    font-size: 12px; /* Adjust the font size as needed */
    /* Additional styles here if needed */
  }
  