.footer {
  background-color: blue;
  color: #002e6a;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.foot {
  position: relative;
}
p {
  color: #002e6a;
}

.border-top {
  background-color: white;
}

@media screen and (max-width: 480px) {
  .foot {
    position: relative;
  }
}


