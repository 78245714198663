.loginForm
{
    background-color: rgba(199, 0, 68, 0.3);
    border-radius: 40px;

}
.button
{
    color: white;
    background-color: #002E6A;
}

.button:hover
{
    color:#C70044;
    background-color: #002E6A;
}
.error {
    color: #002e6a;
    font-size: 14px;
    height: 20px;
    position: absolute;
    font-weight: bolder;
    padding-left: 20px;
}

/* Clicking effect for the "Sign Up" link */
.sign-up-link {
    display: inline-block;
    transition: transform 0.2s, color 0.2s;
    cursor: pointer;
    font-size: inherit; /* Inherit the font size from the parent */
  }
  
  .sign-up-link:hover {
    transform: scale(1.1); 
    color: #c70044; 
  }

  /* Clicking effect for the "Forgot Password?" link */
.forgot-password-link {
    display: inline-block;
    transition: transform 0.2s, color 0.2s;
    cursor: pointer;
  }
  
  .forgot-password-link:hover {
    transform: scale(1.1);
    color: #c70044; 
  }
  
  