
.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width:max-content;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
  }
  
  .popup {
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #C70044;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .popup p {
    margin-bottom: 20px;
    text-align: center;
    font-weight:normal;
  }

  .popup .card-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #C70044;
    border-radius: 8px, 0px, 0px, 8px;
  }
  .card-body button {
    display: block;
    margin: 0 auto;
    
  }

 
  
  /* Additional styles for mobile devices */
  @media (max-width: 700px) {
    .popup {
      width: max-content; /* Adjusted for better responsiveness on small screens */
    }
    
  }
  