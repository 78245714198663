.signupForm {
  background-color: rgba(199, 0, 68, 0.3);
  border-radius: 10px;
}
.button {
  color: white;
  background-color: #002e6a;
}

.button:hover {
  color: #c70044;
  background-color: #002e6a;
}

p {
  font-size: 15px;
  font-weight: bolder;
}

.col-sm-2 {
  height: 40px; /* or any other height you prefer */
  display: flex;
  align-items: center;
}
.error {
  color: #002e6a;
  font-size:medium;
  height: 15px;
  position: relative;
  font-weight: 450;
  margin-bottom: 15px;
  margin-left: 0px;
  font-family: Roboto;
}

.cityerror {
  color: #002e6a;
  font-size: 12px;
  height: 15px;
  position: relative;
  font-weight: 450;
  margin-bottom:45px;
  margin-left: -14px;
}
.form-control {
  height: 40px;
}
.termscondition-link {
  display: inline-block;
  transition: transform 0.2s, color 0.2s;
  cursor: pointer;
  
}

.termscondition-link:hover {
  transform: scale(1.1);
  color: #c70044; 
}

@media screen and (max-width: 480px) {
  .rounded-pill {
    width: 70vw;
    margin-left: -20px;
  }
  .lastname {
    
    margin-top: 0px;
  }

  .formbox {
  margin-left: 27px;
}
  .add {
    margin-top: 0px;
  }
  .city {
    margin-top: 0px;

  }
  .state {
    margin-top: 0px;
  }
  .zip {
    margin-top: 0px;
  }
  .pass {
    margin-top: 0px;
  }
  .sub {
    margin-top: 40px;
    margin-left: 55px;
  }

  .signupbtn{
    margin-right: 50px;
  }

  .error {
    color: #002e6a;
    font-size: medium;
    font-weight: 550;
    margin-bottom: 15px;
    margin-left: 0px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont;
    padding: 5px 0; 
    position: relative;
    left: 10px; 
}
.termscondition-link {
  display: inline-block;
  transition: transform 0.2s, color 0.2s;
  cursor: pointer;
  margin-left: 23px;
}

.termscondition-link:hover {
  transform: scale(1.1);
  color: #c70044; 
}
.bb {
  width: 95vw;
  margin-left: -38px;
}

}
