.image-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.card {
    margin-right: 1rem;
}

@media (max-width: 768px) {
    .image-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
