.box {
  margin: 0 auto; /* center horizontally */
}
.dropdownCSS {
  border-radius: 40px;
  border: solid black 2px;
  font-size: 20px;
  font-weight: bolder;
  color: #002e6a;
}

.image-container {
  position: relative;
  width: 470px;
  height: 370px;
  padding: 0px 0px 0px 0px;
  overflow: hidden;
}

.card-title-design {
  color: #002e6a;
  font-size: 19px;
}

.add-to-cart {
  background-color: #c70044;
  border: none;
  color: white;
  font-weight: bold;
}

.add-to-cart:hover {
  background-color: #c70044;
  color: white;
  font-weight: bold;
}
.priceTag {
  font-size: 23px;
  font-weight: 400;
  color: #c70044;
}
.product-inner-box .icons {
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all 0.2s ease-in-out, transfrom 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}
.product-inner-box img {
  box-shadow: 0 33px 61px -29px rgb(0 0 0 / 26%);
}

.heart-buttonUnselected {
  color: white;
}
.heart-buttonSelected {
  color: red;
}

.divider {
  border-bottom: 2px solid #eee;
  height: 7px;
  list-style: none outside none;
  width: 100%;
  clear: both;
}

.card-body {
  margin-bottom: -5px;
  margin-top: -9px;
}

.mainPage {
  margin-left: 15px;
  margin-right: 15px;
}

h3 {
  /* margin-top: 20%; */
  font-weight: 300;
  color: #002e6a;
  font-size: 30px;
  margin-left: 1%;
}
.oldPrice {
  text-decoration: line-through;
  text-decoration-thickness: 1.85px;
  font-size: 20px;

  color: gray;
}

.salePrice {
  margin-right: 16px;
}

.off {
  position: absolute;
  top: 0;
  right: 0;
  background: #f44336;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 0 0 0 8px;
}

.new {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  margin-left: -16px;
  margin-top: -16px;
}
/* .homeCard {
  width: 470px;
} */

/* .rrr {
    --bs-gutter-x: -1051.5px;
  } */

@media screen and (max-width: 480px) {
  .image-container {
    width: 250px;
    height: 249px;
  }
  .mainPage {
    margin-left: 3px;
    margin-right: 3px;
  }
  .g-3,
  .gy-3 {
    --bs-gutter-y: 0.5rem;
  }
  .g-3,
  .gx-3 {
    --bs-gutter-x: 0.25rem;
  }
  .pt-3 {
    padding-top: 0.4rem !important;
  }

  .g-4,
  .gy-4 {
    --bs-gutter-y: 0.5rem;
  }

  .g-4,
  .gx-4 {
    --bs-gutter-x: 0.25rem;
  }
  .pt-4 {
    padding-top: 0.4rem !important;
  }
  .off {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 0 4px 0 8px;
  }

  .new {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0;
    margin-left: -10px;
    margin-top: -11px;
  }
}
