body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(249, 249, 249);
  padding-top:80px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
h1 {
  color: #002e6a;
  font-weight: 700;
}

.reviews {
  background-color: #c70044;
  border-radius: 70px;
  height: 80px;
}
.reviews > p {
  color: white;
  font-size: 30px;
}
.disimg {
  height: 400px;
  width: 250px;
  box-shadow: 5px 5px #c70044;
}

.off {
  position: absolute;
  top: 0;
  right: 0;
  background: #f44336;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 0 0 0 8px;

}

@media (max-width: 576px) {
  .reviews p {
    font-size: 14px;
    line-height: 1.5;
  }
  .off {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 0 0 0 8px;
  }
  .product-list {
    display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right:-12px
    
  }

  .card {
    /* width:185px; */
    width: calc(100%); 
    margin-left: -5px;
    
  }
  
}
@media (max-width: 370px) {
  .reviews p {
    font-size: 14px;
    line-height: 1.5;
  }
  .off {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 0 0 0 8px;
  }
  .product-list {
    margin-right: -12px;
  }

  .card {
    width:170px;
    margin-left: -5px;
  }
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 100px;
}

.navbar-nav {
  font-weight: bolder;
  font-size: 18px;
  
}
.nav-link {
  color: #002e6a;
}
.nav-link:hover {
  color: #c70044;
}

.navbar-nav .nav-item.active .nav-link {
  color: #c70044 !important; /* Ensure active links are colored correctly */
  font-weight: bold; /* Optional for emphasis */
}

/* .navbar-nav .nav-item.active .nav-link:hover {
  color: #a50038; 
} */


.btn {
  color: #002e6a;
}

.button {
  color: white;
}

.btn:hover {
  color: #c70044;
}

.dropdown-item {
  font-weight: bolder;
  color: #002e6a;
}
.dropdown-item:hover {
  color: #c70044;
  background-color: white;
}
.navbar-collapse.show {
  background-color: whitesmoke !important;
  transition:ease;
}
#navbarNav {
  background-color: white !important;
  transition: ease;
  width: 100vw;
  margin-left: -12px;
  margin-right: -12px;
}

.mobile {
  margin-left: 20px;
}



@media screen and (max-width: 480px) {
  .logo {
    width: 150px;
    height: 60px;
    margin-right: 0px;
  }
  .mobileSearch{
    
    margin-bottom: -1px;
    width: 100%;
    margin-right: 0px;
    margin-top: 20px;
    
    
  }
  .nav-link {
    font-weight: 400;
  }
  .mobile {
    /* margin-left: 70px; */
    display: flex;
    align-items: flex-start;
    margin-left: 15px;
  
    
  }
  #navbarNav {
    background-color: rgb(255, 255, 255) !important;
    transition:ease-in-out;
    border-radius:5px;
    /* border-color: gainsboro; */
    border-color: gainsboro;
    border-style:solid;
    margin-left: 190px;
    margin-top: 7px;
    margin-right: 0.25px;
   

   
  }
  /* .hri { 
    display: block;
    margin-block-end: auto;
    margin-block-start:  none;
    border: 1.1px inset;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 42vw;
} */

.hri { 
  display: block;
  margin: 0 auto; /* Center horizontally if needed */
  border: 0.5px inset; /* Adjusted border thickness for better alignment */
  overflow: hidden;
  width: 48vw; /* Maintains the original width specification */
  margin-left: -15px;
  
}
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 78px;
}
  
.nav-item {
  font-weight: 150;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}




}



@media screen and (max-width: 350px) {
  .logo {
    width: 110px;
    height: 40px;
    margin-right: 0px;
    margin-right: -15px;
  }
  .mobileSearch{
    
    margin-bottom: 10px;
    width: 95%;
    margin-right: 1px;
    
  }
  .nav-item {
    font-weight: 450;
  }
  .mobile {
    /* margin-left: 70px; */
    display: flex;
    align-items: flex-start;
    margin-left: 15px;
  
    
  }
  #navbarNav {
    background-color: rgb(255, 255, 255) !important;
    transition:none;
    border-radius: 7px;
    border-color: gainsboro;
    border-style: solid;
    margin-left: 190px;
    margin-top: 9px;
   

   
  }
  .hri { 
    display: block;
    -webkit-margin-after: auto;
            margin-block-end: auto;
    -webkit-margin-before:  none;
            margin-block-start:  none;
    border: 1px inset;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 42vw;
}
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 78px;
  }
  


}


.footer {
  background-color: blue;
  color: #002e6a;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.foot {
  position: relative;
}
p {
  color: #002e6a;
}

.border-top {
  background-color: white;
}

@media screen and (max-width: 480px) {
  .foot {
    position: relative;
  }
}



.image-carousel {
  position: relative;
  width: 95%;
  height: 60vh; /* Change height to 30% of viewport height */
  margin-left: auto;
  margin-right: auto;
}

.carousel-item {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.carousel-item.active {
  opacity: 1;
}

.image-carousel img {
  height: 100%; /* Set image height to 100% of parent container */
  object-fit: contain; /* Scale image to fill the container */

  object-position: center center;
}

.carousel-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: none;
  background: none;
  font-size: 80px;
  cursor: pointer;
  color: #c70044;
  transition: opacity 0.8s ease-in-out;
  
}

.carousel-button:hover {
  opacity: 1;
  color: #002e6a;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

@media screen and (max-width: 480px) {
  .image-carousel img {
    object-fit: contain;
    object-position: center;
  }
  .image-carousel {
    height: 30vh;
    width: 98%;
  }
  .carousel-button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border: none;
    background: none;
    font-size: 20px;
    cursor: none;
    color: #c70044;
    transition: opacity 1s ease-in-out;
  }
}

.loginForm
{
    background-color: rgba(199, 0, 68, 0.3);
    border-radius: 40px;

}
.button
{
    color: white;
    background-color: #002E6A;
}

.button:hover
{
    color:#C70044;
    background-color: #002E6A;
}
.error {
    color: #002e6a;
    font-size: 14px;
    height: 20px;
    position: absolute;
    font-weight: bolder;
    padding-left: 20px;
}

/* Clicking effect for the "Sign Up" link */
.sign-up-link {
    display: inline-block;
    transition: color 0.2s, -webkit-transform 0.2s;
    transition: transform 0.2s, color 0.2s;
    transition: transform 0.2s, color 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
    font-size: inherit; /* Inherit the font size from the parent */
  }
  
  .sign-up-link:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
    color: #c70044; 
  }

  /* Clicking effect for the "Forgot Password?" link */
.forgot-password-link {
    display: inline-block;
    transition: color 0.2s, -webkit-transform 0.2s;
    transition: transform 0.2s, color 0.2s;
    transition: transform 0.2s, color 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
  }
  
  .forgot-password-link:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    color: #c70044; 
  }
  
  
.signupForm {
  background-color: rgba(199, 0, 68, 0.3);
  border-radius: 10px;
}
.button {
  color: white;
  background-color: #002e6a;
}

.button:hover {
  color: #c70044;
  background-color: #002e6a;
}

p {
  font-size: 15px;
  font-weight: bolder;
}

.col-sm-2 {
  height: 40px; /* or any other height you prefer */
  display: flex;
  align-items: center;
}
.error {
  color: #002e6a;
  font-size:medium;
  height: 15px;
  position: relative;
  font-weight: 450;
  margin-bottom: 15px;
  margin-left: 0px;
  font-family: Roboto;
}

.cityerror {
  color: #002e6a;
  font-size: 12px;
  height: 15px;
  position: relative;
  font-weight: 450;
  margin-bottom:45px;
  margin-left: -14px;
}
.form-control {
  height: 40px;
}
.termscondition-link {
  display: inline-block;
  transition: color 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, color 0.2s;
  transition: transform 0.2s, color 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  
}

.termscondition-link:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: #c70044; 
}

@media screen and (max-width: 480px) {
  .rounded-pill {
    width: 70vw;
    margin-left: -20px;
  }
  .lastname {
    
    margin-top: 0px;
  }

  .formbox {
  margin-left: 27px;
}
  .add {
    margin-top: 0px;
  }
  .city {
    margin-top: 0px;

  }
  .state {
    margin-top: 0px;
  }
  .zip {
    margin-top: 0px;
  }
  .pass {
    margin-top: 0px;
  }
  .sub {
    margin-top: 40px;
    margin-left: 55px;
  }

  .signupbtn{
    margin-right: 50px;
  }

  .error {
    color: #002e6a;
    font-size: medium;
    font-weight: 550;
    margin-bottom: 15px;
    margin-left: 0px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont;
    padding: 5px 0; 
    position: relative;
    left: 10px; 
}
.termscondition-link {
  display: inline-block;
  transition: color 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, color 0.2s;
  transition: transform 0.2s, color 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
  margin-left: 23px;
}

.termscondition-link:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: #c70044; 
}
.bb {
  width: 95vw;
  margin-left: -38px;
}

}

/* Default styles for desktop */
.cart-image {
  height: 190px;
  width: 150px;
  margin: 10px;
  object-fit: cover;
  box-shadow: 2px 2px 8px #c70044;
}
.cart-image-container {
  height: 200px;
  width: 200px;
}


.product-desc-container {
  height: 200px;
  width: 300px;
}

.cart-item > div {
  margin: 30px;
}

.clear-cart {
  color: red;
}

.clear-cart:hover {
  color: black;
}
.bb {
  display: flex;
  justify-content: flex-end;
}

/* Media query for mobile screens */
@media only screen and (max-width: 768px) {
  .cart-image {
    height: 170px;
    width: 150px;
    margin: 5px;
  }

  .cart-item > div {
    margin: 14px;
  }
  .bb {
    display: flex;
    justify-content: flex-end;
  }
  .bs{
    padding-left: 85px;
    padding-right: 85px;
    
  }
  
}

.button
{
    color: white;
    background-color: #002E6A;
}

.button:hover
{
    color:#C70044;
    background-color: #002E6A;
}

.product-form-container {
    display: flex;
    justify-content: center;
}

.form-group {
    margin-bottom: 1.5rem;
}

.size-input,
.quantity-input {
    display: inline-block;
    margin-right: 10px;
}

.table-dark th{
    color: white;
}

.pagination-text {
    font-size: 12px; /* Adjust the font size as needed */
    /* Additional styles here if needed */
  }
  
.nav-link.active {
    /* border-bottom: 2px solid #C70044; */
    color: #C70044 ; /* add a bottom border */
}


@media screen and (max-width: 480px) {
    .mobile {
        /* margin-left: 70px; */
        display: flex;
        align-items: flex-start;
        margin-left: 15px;
    }
    .nav-item{
        font-weight: 250;
    }
    .hrik { 
        display: block;
        -webkit-margin-after: auto;
                margin-block-end: auto;
        -webkit-margin-before:  none;
                margin-block-start:  none;
        
        border: 1.2px solid;
        overflow: hidden;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: -12px;
        width: 180px;
    }
   
    
}

.dis-img {
  width: 320px;
  height: 430px;
  margin-bottom: 18px;
}
.size-button {
  border: 2px solid black;
  margin: 10px;
  border-radius: 10%;

  padding: 10px;
}
.size-button-active {
  border: 2px solid #c70044;
  margin: 10px;
  border-radius: 100%;
  background-color: #f8c5d7;
  padding: 10px;
}
.size-button:hover {
  border: 2px solid #c70044;
  background-color: #f8c5d7;
  border-radius: 100%;
  color: black;
}
.productPrice {
  font-size: 28px;
  font-weight: 500;
  color: #c70461;
}
.allName {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.pName {
  font-weight: 380;
  font-size: 29px;
}

.off {
  position: absolute;
  top: 0;
  right: 0;
  background: #f44336;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 0 0 0 8px;
}

@media screen and (max-width: 480px) {
  .allImages > .col-md-6 {
    flex-basis: 49%;

    max-width: 50%;
    justify-content: space-between;
  }
  .dis-img {
    height: 292px;
    width: 195px;
  }
  .rowKa {
    --bs-gutter-x: -0.25rem;
    --bs-gutter-y: -9.6rem;
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1.5px;
    -webkit-column-gap: 1.5px;
            column-gap: 1.5px;
    grid-row-gap: 1px;
    row-gap: 1px;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
    margin-bottom: -90px;
  }
  .off {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 0 0 0 8px;
  }
}


.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1000;
    width:-webkit-max-content;
    width:max-content;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
  }
  
  .popup {
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #C70044;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .popup p {
    margin-bottom: 20px;
    text-align: center;
    font-weight:normal;
  }

  .popup .card-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #C70044;
    border-radius: 8px, 0px, 0px, 8px;
  }
  .card-body button {
    display: block;
    margin: 0 auto;
    
  }

 
  
  /* Additional styles for mobile devices */
  @media (max-width: 700px) {
    .popup {
      width: -webkit-max-content;
      width: max-content; /* Adjusted for better responsiveness on small screens */
    }
    
  }
  
.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    width: 70px;
    height: 70px;
    position: relative;
  }
  
  .loader:before {
    content: "";
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 6px solid #c70044;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: pulse 1s ease-in-out infinite;
            animation: pulse 1s ease-in-out infinite;
  }
  
  .loader:after {
    content: "";
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: #c70044;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
  }
  
  .loader-text {
    font-size: 24px;
    margin-top: 20px;
    color: #c70044;
    font-family: Arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(0.6);
              transform: scale(0.6);
      opacity: 1;
    }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(0.6);
              transform: scale(0.6);
      opacity: 1;
    }
  }
  
  @keyframes pulse {
    0% {
      -webkit-transform: scale(0.6);
              transform: scale(0.6);
      opacity: 1;
    }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(0.6);
              transform: scale(0.6);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .content {
    display: none;
  }
  
  .loaded .loader-container {
    display: none;
  }
  
  .loaded .content {
    display: block;
  }
  
.catimg {
  height: 320px;
  width: 200px;
  object-Fit: cover;
  object-Position: center center;
  width: 100%,
}

/* .catname {
  color: white;
  font-size: 35px;
  font-weight: 600;
  text-shadow: 4px 4px 10px #c70044, 3px 3px 10px #002e6a;
  margin-left: 19px;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-left: 35px;
  animation: pop-in 1s ease-in-out both;
} */

.category-link {
  text-decoration: none; /* Remove underline from the Link */
  color: inherit; /* Inherit the text color */
}
.catname {
  color: #002e6a;
  font-size: 22px;
  font-weight: 400;
  margin-top: 1px; /* Adjust margin to position the text below the image */
  display: block; /* Ensure the element takes up the full width */
  text-align: center; /* Center the text */
  -webkit-animation: pop-in 1s ease-in-out both;
          animation: pop-in 1s ease-in-out both;
  text-decoration: none;
}
.catname:hover {
  text-shadow: 1px 1px 1px #c70044;
}



.review {
  display: flex;
  justify-content: space-between;
}

/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Pagination button styles */
.pagination button {
  background-color: #002E6A;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

/* Active page button style */
.pagination button.active {
  background-color: #c70044;
  color: white;
  font-weight: bold;
}


@-webkit-keyframes pop-in {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}


@keyframes pop-in {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  .g-4,
  .gy-4 {
    --bs-gutter-y: -0.5rem;
  }

  .review {
    display: flex;
    justify-content: space-between;
  }

}

@media screen and (max-width: 380px) {
  .g-4,
  .gy-4 {
    --bs-gutter-y: 1rem;
  }

  .review {
    display: flex;
    justify-content: space-between;
  }

  .android {
    width: 345px;
  }

}
.box {
  margin: 0 auto; /* center horizontally */
}
.dropdownCSS {
  border-radius: 40px;
  border: solid black 2px;
  font-size: 20px;
  font-weight: bolder;
  color: #002e6a;
}

.image-container {
  position: relative;
  width: 470px;
  height: 370px;
  padding: 0px 0px 0px 0px;
  overflow: hidden;
}

.card-title-design {
  color: #002e6a;
  font-size: 19px;
}

.add-to-cart {
  background-color: #c70044;
  border: none;
  color: white;
  font-weight: bold;
}

.add-to-cart:hover {
  background-color: #c70044;
  color: white;
  font-weight: bold;
}
.priceTag {
  font-size: 23px;
  font-weight: 400;
  color: #c70044;
}
.product-inner-box .icons {
  right: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  transition: all 0.2s ease-in-out, transfrom 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}
.product-inner-box img {
  box-shadow: 0 33px 61px -29px rgb(0 0 0 / 26%);
}

.heart-buttonUnselected {
  color: white;
}
.heart-buttonSelected {
  color: red;
}

.divider {
  border-bottom: 2px solid #eee;
  height: 7px;
  list-style: none outside none;
  width: 100%;
  clear: both;
}

.card-body {
  margin-bottom: -5px;
  margin-top: -9px;
}

.mainPage {
  margin-left: 15px;
  margin-right: 15px;
}

h3 {
  /* margin-top: 20%; */
  font-weight: 300;
  color: #002e6a;
  font-size: 30px;
  margin-left: 1%;
}
.oldPrice {
  text-decoration: line-through;
  text-decoration-thickness: 1.85px;
  font-size: 20px;

  color: gray;
}

.salePrice {
  margin-right: 16px;
}

.off {
  position: absolute;
  top: 0;
  right: 0;
  background: #f44336;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 0 0 0 8px;
}

.new {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  margin-left: -16px;
  margin-top: -16px;
}
/* .homeCard {
  width: 470px;
} */

/* .rrr {
    --bs-gutter-x: -1051.5px;
  } */

@media screen and (max-width: 480px) {
  .image-container {
    width: 250px;
    height: 249px;
  }
  .mainPage {
    margin-left: 3px;
    margin-right: 3px;
  }
  .g-3,
  .gy-3 {
    --bs-gutter-y: 0.5rem;
  }
  .g-3,
  .gx-3 {
    --bs-gutter-x: 0.25rem;
  }
  .pt-3 {
    padding-top: 0.4rem !important;
  }

  .g-4,
  .gy-4 {
    --bs-gutter-y: 0.5rem;
  }

  .g-4,
  .gx-4 {
    --bs-gutter-x: 0.25rem;
  }
  .pt-4 {
    padding-top: 0.4rem !important;
  }
  .off {
    position: absolute;
    top: 0;
    right: 0;
    background: #f44336;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 0 4px 0 8px;
  }

  .new {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0;
    margin-left: -10px;
    margin-top: -11px;
  }
}

.image-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.card {
    margin-right: 1rem;
}

@media (max-width: 768px) {
    .image-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

.form-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.star-rating {
    display: flex;
    justify-content: center;
    align-items: center;
}

.star-rating i {
    color: #ccc;
    font-size: 1.5rem;
    cursor: pointer;
}

.star-rating i.selected {
    color: #ffc107;
}
