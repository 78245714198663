.form-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.star-rating {
    display: flex;
    justify-content: center;
    align-items: center;
}

.star-rating i {
    color: #ccc;
    font-size: 1.5rem;
    cursor: pointer;
}

.star-rating i.selected {
    color: #ffc107;
}